import {
    GET_CATEGORIES,
    UPDATE_FORM_MODULE,
    INIT_FORM_MODULE,
  } from '../../shared/constants/ActionTypes';
  
  const initialRoleManagement = {
    categories: [],
    total: 0,
    formModules: {},
  };
  
  // TODO: HAVE TO ADD PAGE AND SEARCH IN STATE
  
  const propertycategoryManagementReducer = (state = initialRoleManagement, action) => {
    switch (action.type) {
      case GET_CATEGORIES:
        return {
          ...state,
          categories: action.payload.data,
          total: action.payload.total,
        };
      case INIT_FORM_MODULE:
        return {
          ...state,
          formModules: action.payload,
        };
      case UPDATE_FORM_MODULE:
        let newState = {
          ...state.formModules,
          [action.payload.moduleId]: {
            ...state.formModules[action.payload.moduleId],
            [action.payload.fieldName]: action.payload.value,
          },
        };
        return {
          ...state,
          formModules: newState,
        };
      default:
        return state;
    }
  };
  
  export default propertycategoryManagementReducer;
  