//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//ANALYTICS-DASHBOARD
export const GET_ANALYTICS_DATA = 'get_analytics_data';

//ECOMMERCE-DASHBOARD
export const GET_ECOMMERCE_DATA = 'get_ecommerce_data';

//ACADEMY-DASHBOARD
export const GET_ACADEMY_DATA = 'get_academy_data';

//CRM-DASHBOARD
export const GET_CRM_DATA = 'get_crm_data';

//CRYPTO-DASHBOARD
export const GET_CRYPTO_DATA = 'get_crypto_data';

//CRYPTO-DASHBOARD
export const GET_HC_DATA = 'get_hc_data';

//METRICS-DASHBOARD
export const GET_METRICS_DATA = 'get_metrics_data';

//WIDGETS_DASHBOARD
export const GET_WIDGETS_DATA = 'get_widgets_data';

//MAIL-APP
export const GET_MAIL_LIST = 'get_mail_list';
export const GET_FOLDER_LIST = 'get_folder_list';
export const GET_LABEL_LIST = 'get_label_list';
export const TOGGLE_MAIL_DRAWER = 'toggle_mail_drawer';
export const COMPOSE_MAIL = 'compose_mail';
export const GET_MAIL_DETAIL = 'get_mail_detail';
export const UPDATE_MAIL_FOLDER = 'update_mail_folders';
export const UPDATE_MAIL_LABEL = 'update_mail_label';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const UPDATED_MAIL_DETAIL = 'updated_mail_detail';
export const CHANGE_READ_STATUS = 'change_read_status';
export const GET_CONNECTION_LIST = 'get_connection_list';
export const NULLIFY_MAIL = 'nullify_mail';

//TODO-APP
export const GET_TASK_LIST = 'get_task_list';
export const CREATE_NEW_TASK = 'create_new_task';
export const TOGGLE_TODO_DRAWER = 'toggle_todo_drawer';
export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST';
export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST';
export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST';
export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST';
export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER';
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST';

//CONTACT_APP
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const GET_CONTACT_FOLDER_LIST = 'GET_CONTACT_FOLDER_LIST';
export const GET_CONTACT_LABEL_LIST = 'GET_CONTACT_LABEL_LIST';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const UPDATE_CONTACT_LABEL = 'UPDATE_CONTACT_LABEL';
export const UPDATE_CONTACT_STARRED_STATUS = 'UPDATE_CONTACT_STARRED_STATUS';
export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL';
export const TOGGLE_CONTACT_DRAWER = 'TOGGLE_CONTACT_DRAWER';
export const UPDATE_CONTACT_DETAIL = 'UPDATE_CONTACT_DETAIL';
export const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT';

//SCRUMBOARD_APP
export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_SCRUM_LABEL_LIST = 'GET_SCRUM_LABEL_LIST';
export const GET_BOARDS = 'GET_BOARDS';
export const GET_BOARD_DETAIL = 'GET_BOARD_DETAIL';
export const ADD_BOARD_LIST = 'ADD_BOARD_LIST';
export const ADD_LIST_CARD = 'ADD_LIST_CARD';
export const EDIT_LIST_CARD = 'EDIT_LIST_CARD';
export const DELETE_LIST_CARD = 'DELETE_LIST_CARD';
export const DELETE_BOARD = 'DELETE_BOARD';
export const ADD_NEW_BOARD = 'ADD_NEW_BOARD';
export const DELETE_LIST = 'DELETE_LIST';
export const EDIT_BOARD_DETAIL = 'EDIT_BOARD_DETAIL';
export const EDIT_BOARD_LIST = 'EDIT_BOARD_LIST';

//CHAT_APP
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_USER_MESSAGES = 'get_user_messages';
export const ADD_NEW_MESSAGE = 'add_new_message';
export const EDIT_MESSAGE = 'edit_message';
export const DELETE_MESSAGE = 'delete_message';
export const DELETE_USER_MESSAGES = 'delete_user_messages';
export const TOGGLE_CHAT_DRAWER = 'toggle_chat_drawer';
export const SELECT_USER = 'select_user';

//WALL_APP
export const GET_WALL_DATA = 'get_wall_data';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_NEW_POST = 'create_new_post';
export const UPDATE_POST = 'update_post';

//USER_LIST
export const GET_USER_LIST = 'GET_USER_LIST';

//ECOMMERCE_LIST
export const GET_ECOMMERCE_LIST = 'get_ecommerce_list';
export const SET_PRODUCT_VIEW_TYPE = 'set_product_view_type';
export const SET_FILTER_DATA = 'set_filter_data';
export const SET_PRODUCT_DATA = 'set_product_data';
export const GET_RECENT_ORDER = 'get_recent_order';
// export const GET_CUSTOMERS = 'get_customers';
export const ADD_CART_ITEM = 'add_cart_item';
export const REMOVE_CART_ITEM = 'remove_cart_item';
export const UPDATE_CART_ITEM = 'update_cart_item';
export const SET_CART_ITEMS = 'set_cart_items';

//CK-EDITOR
export const GET_BALLOON_BLOCK_DATA = 'get_balloon_block_data';
export const UPDATE_BALLOON_BLOCK_DATA = 'update_balloon_block_data';
export const GET_BALLOON_DATA = 'get_balloon_data';
export const UPDATE_BALLOON_DATA = 'update_balloon_data';
export const GET_CLASSIC_DATA = 'get_classic_data';
export const UPDATE_CLASSIC_DATA = 'update_classic_data';
export const GET_INLINE_DATA = 'get_inline_data';
export const UPDATE_INLINE_DATA = 'update_inline_data';
export const GET_DOCUMENT_DATA = 'get_document_data';
export const UPDATE_DOCUMENT_DATA = 'update_document_data';
export const GET_CUSTOM_DATA = 'get_custom_data';
export const UPDATE_CUSTOM_DATA = 'update_custom_data';

//GALLERY
export const GET_GALLERY_PHOTO = 'get_gallery_photo';

// Users
export const GET_USERS = 'get_users';
export const ADD_USER = 'add_user';
export const EDIT_USER = 'edit_user';
export const SET_ROLES = 'set_roles';
export const SET_SUBROLES = 'set_subroles';

// Users
export const GET_CUSTOMERS = 'get_customers';
export const ADD_CUSTOMER = 'add_customer';
export const EDIT_CUSTOMER = 'edit_customer';

export const GET_EXCLUSIVE_PROPERTY = 'get_exclusiveproperty';
export const ADD_EXCLUSIVE_PROPERTY = 'add_exclusiveproperty';
export const EDIT_EXCLUSIVE_PROPERTY = 'edit_exclusiveproperty';
export const DELETE_EXCLUSIVE_PROPERTY = 'delete_exclusiveproperty';

export const GET_LENDERS = 'get_lenders';
export const ADD_LENDER = 'add_lender';
export const EDIT_LENDER = 'edit_lender';
export const DELETE_LENDER = 'delete_lender';

// Pages
export const GET_PAGES = 'get_pages';
export const ADD_PAGE = 'add_page';
export const EDIT_PAGE = 'edit_page';

// Emails
export const GET_EMAILS = 'get_emails';
export const ADD_EMAIL = 'add_email';
export const EDIT_EMAIL = 'edit_email';

// Profile
export const UPDATE_PROFILE = 'update_profile';
export const GET_PROFILE = 'get_profile';
export const CHANGE_PASSWORD = 'change_password';
export const ADD_PROFILE = 'add_profile';

// Students
export const GET_STUDENTS = 'get_students';
export const EDIT_STUDENT = 'edit_student';
export const DELETE_STUDENT = 'delete_student';

// Tutors
export const GET_TUTORS = 'get_tutors';
export const EDIT_TUTOR = 'edit_tutor';
export const DELETE_TUTOR = 'delete_tutor';

// Course Categories
export const GET_COURSE_CATEGORIES = 'get_course_categories';
export const EDIT_COURSE_CATEGORIES = 'edit_course_categories';
export const DELETE_COURSE_CATEGORIES = 'delete_course_categories';
export const ADD_COURSE_CATEGORY = 'add_course_category';

// Role Management
export const GET_ROLES = 'get_roles';
export const EDIT_ROLE = 'edit_role';
export const ADD_ROLE = 'add_role';

export const ADD_MODULE = 'add_module';
export const ADD_QUESTION = 'add_question';

export const GET_MODULES = 'get_modules';
export const GET_QUESTIONS = 'get_question';
export const GET_PRE_QULI_USERS = 'get_preQualified_user';



// Property Category Management
export const ADD_CATEGORY = 'add_categories';
export const GET_CATEGORIES = 'get_categories';

export const INIT_FORM_MODULE = 'init_form_module';
export const UPDATE_FORM_MODULE = 'update_form_module';

// Live Courses
export const GET_LIVE_COURSES = 'get_live_courses';

// Live Course Session
export const GET_LIVE_COURSE_SESSION = 'get_live_course_session';
export const SET_LIVE_COURSE_ID = 'set_live_course_id';

// Books
export const GET_BOOKS = 'get_books';

// Product Types
export const GET_PRODUCT_TYPES = 'get_product_types';

//Advertisement
export const GET_ADVERTISEMENT = 'get_advertisement';
//Coupon
export const GET_COUPONS = 'get_coupons';

//Client logo
export const GET_CLIENT_LOGO = 'get_client_logo';

//Professional Profile
export const GET_PROFESSIONAL_PROFILE = 'get_professional_profile';

//Scientific Evidence
export const GET_SCIENTIFIC_EVIDENCE = 'get_scientific_evidence';
//Products
export const GET_PRODUCTS = 'get_products';
export const SET_PAGE = 'set_page';
export const SET_SEARCH = 'set_search';
export const SET_COURSE_TYPE = 'set_course_type';

// QnA

export const GET_QA = 'get_QnA';
export const SET_PRODUCT_ID = 'set_product_id';

// Testimonial
export const GET_TESTIMONIAL = 'get_testimonial';

// metaData
export const GET_METADATA = 'get_metaData';
// material content
export const GET_MATERIAL = 'get_material';
// home page testimonial
export const GET_HOME_PAGE_TESTIMONIAL = 'get_home_page_testimonial';
// value content
export const GET_VALUE_CONTENT = 'get_value_content';
// video content
export const GET_VIDEO_CONTENT = 'get_video_content';

// video content item
export const GET_VIDEO_CONTENT_ITEM = 'get_video_content_item';

// video product tutor
export const GET_PRODUCT_TUTOR = 'get_product_tutor';
// book category
export const GET_BOOKS_CATEGORIES = 'get_book_category';
export const SET_BOOK_TYPE = 'set_book_type';
