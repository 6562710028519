import {
  UPDATE_PROFILE,
  GET_PROFILE,
  FETCH_START,
  FETCH_ERROR,
  FETCH_SUCCESS,
  ADD_PROFILE,
  SHOW_MESSAGE,
} from '../../shared/constants/ActionTypes';

import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getProfile = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get('/profile', {
        params: {},
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_PROFILE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const updateProfile = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .put('/profile/update', body)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: UPDATE_PROFILE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const changePassword = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post('/changepassword', body)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if(data?.data?.succeeded) {
            dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: data?.data?.message
            });
          }
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data?.data?.msg
              ? data?.data?.msg
              : 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.msg
            ? error?.response?.data?.msg
            : error?.message,
        });
      });
  };
};

export const addProfile = (profile) => {
  return (dispatch) => {
    dispatch({type: ADD_PROFILE, payload: profile});
  };
};
