import {
  GET_ROLES,
  GET_MODULES,
  UPDATE_FORM_MODULE,
  INIT_FORM_MODULE,
} from '../../shared/constants/ActionTypes';

const initialRoleManagement = {
  roles: [],
  roleCount: 0,
  modules: [],
  moduleCount: 0,
  formModules: {},
};

// TODO: HAVE TO ADD PAGE AND SEARCH IN STATE

const roleManagementReducer = (state = initialRoleManagement, action) => {
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload.data,
        roleCount: action.payload.roleCount,
      };
    case GET_MODULES:
      return {
        ...state,
        modules: action.payload.data,
        moduleCount: action.payload.count,
      };
    case INIT_FORM_MODULE:
      return {
        ...state,
        formModules: action.payload,
      };
    case UPDATE_FORM_MODULE:
      let newState = {
        ...state.formModules,
        [action.payload.moduleId]: {
          ...state.formModules[action.payload.moduleId],
          [action.payload.fieldName]: action.payload.value,
        },
      };
      return {
        ...state,
        formModules: newState,
      };
    default:
      return state;
  }
};

export default roleManagementReducer;
