import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

function ShowModules({ module }) {

  return (
    <div className='show-customer-details'>
      {/* <p>Question : {module.question}</p> */}
      <Row>
        <Col>
          <p> Name : </p>
        </Col>
        <Col>
          <p>{module.firstName} {module.lastName}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p> Email : </p>
        </Col>
        <Col>
          <p>{module.email}</p>
        </Col>

      </Row>
      <Row>
        <Col>
          <p> Phone Number : </p>
        </Col>
        <Col>
          <p>{module.phoneNumber}</p>
        </Col>
      </Row>

    </div>
  );
}

export default ShowModules;

ShowModules.propTypes = {
  module: PropTypes.object,
};
