import {useState, useEffect, useDebugValue} from 'react';

export function usePermissions(moduleName, profile) {
  const [permission, setPermission] = useState();

  useEffect(() => {
    if(profile?.role === "SuperAdmin") {
      setPermission({
        create: true,
        read: true,
        update: true,
        delete: true,
      });
    } else {
      const index = profile?.accessModules?.findIndex(
        (module) => module?.moduleName?.toString().toUpperCase() === moduleName,
      );
      if (index > -1) {
        setPermission(profile?.accessModules[index]);
      } else {
        setPermission({
          create: false,
          read: false,
          update: false,
          delete: false,
        });
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDebugValue(permission ?? 'loading...');

  return permission;
}
