import {
    SET_PAGE,
    SET_SEARCH,
    GET_LENDERS,
  } from '../../shared/constants/ActionTypes';
  
  const initialLenders = {
    lenders: [],
    lenderCount: 0,
    page: 1,
    search: ''
  };
  
  const lendersReducer = (state = initialLenders, action) => {
    switch (action.type) {
      case GET_LENDERS:
        return {
          ...state,
          lenders: action.payload.data,
          lenderCount: action.payload.total,
        };
  
      case SET_PAGE:
        return {
          ...state,
          page: action.payload,
        };
  
      case SET_SEARCH:
        return {
          ...state,
          search: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default lendersReducer;
  