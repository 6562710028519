import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  ADD_QUESTION,
  GET_QUESTIONS,
  SET_SEARCH,
  SET_PAGE
} from '../../shared/constants/ActionTypes';

import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const addQuestions = (formData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/savequestion', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: ADD_QUESTION, payload: data.data.data });
          // dispatch(getModules('', 0));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
export const getQuestions = (limit = 10) => {
  return (dispatch, getState) => {
    const state = getState().mortageoptions;
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('/questions', {
        params: {
          pageNo: state.page,
          pageSize: limit,
          keyword: state.search
        }
        // search: state.search,
        // page: state.page,
        // limit,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_QUESTIONS,
            payload: {
              data: data.data.data,
              count: data.total
            },
          });
          let moduleMap = {};
          // data.data.data.forEach((module) => {
          //   moduleMap = {
          //     ...moduleMap,
          //     [module.id]: {
          //       moduleName: module.moduleName,
          //       moduleid: module.id,
          //       create: false,
          //       read: false,
          //       update: false,
          //       delete: false,
          //     },
          //   };
          // });

          // dispatch(initFormModule(moduleMap));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
export const deleteQuestion = (id) => {

  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post(`/deletequestion?Id=${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          // dispatch({ type: ADD_MODULE, payload: data.data.data });
          dispatch(getQuestions('', 0));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
export const setPage = (page) => {
  return (dispatch) => {
    dispatch({ type: SET_PAGE, payload: page });
  };
};
export const setSearch = (search) => {
  return (dispatch) => {
    dispatch({ type: SET_SEARCH, payload: search });
  };
};

