import {
  SET_PAGE,
  SET_SEARCH,
  GET_CUSTOMERS,
  SET_ROLES,
  SET_SUBROLES,
} from '../../shared/constants/ActionTypes';

const initialUsers = {
  customers: [],
  customerCount: 0,
  page: 1,
  search: '',
  roles: [],
  subroles: [],
};

const customersReducer = (state = initialUsers, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload.data,
        customerCount: action.payload.total,
      };

    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };

    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case SET_SUBROLES:
      return {
        ...state,
        subroles: action.payload,
      };

    default:
      return state;
  }
};

export default customersReducer;
