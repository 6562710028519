import {
  GET_ROLES,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  ADD_MODULE,
  GET_MODULES,
  INIT_FORM_MODULE,
  UPDATE_FORM_MODULE,
  SHOW_MESSAGE,
} from '../../shared/constants/ActionTypes';

import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

export const getRoleManagementData = (search, page) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('/getroles', {
        searchText: search,
        page: page + 1,
        limit: 10,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_ROLES,
            payload: {
              data: data.data.data,
              roleCount: data.data.total
            },
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const editRoleManagement = (formData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/role/update-roles', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          // dispatch({ type: EDIT_ROLE, payload: data.data });
          dispatch(getRoleManagementData('', 0));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const addRolemanagement = (formData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/createrole', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });

          dispatch(getRoleManagementData('', 0));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const deleteRoleManagement = (formData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/role/delete-roles', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });

          dispatch(getRoleManagementData('', 0));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const assignRoleManagement = (formData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/addmoduleaccess', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SHOW_MESSAGE, payload: data?.data?.message });
          dispatch(getRoleManagementData('', 0));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const addModule = (formData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/addmodule', { ModuleName: formData.moduleName })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: ADD_MODULE, payload: data.data.data });
          dispatch(getModules('', 0));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};


export const updateModule = (formData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/module/update-module', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          // dispatch({ type: ADD_MODULE, payload: data.data.data });
          dispatch(getModules('', 0));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getModules = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('/getmodules')
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_MODULES,
            payload: {
              data: data.data.data,
              count: data.total
            },
          });

          let moduleMap = {};
          data.data.data.forEach((module) => {
            moduleMap = {
              ...moduleMap,
              [module.id]: {
                moduleName: module.moduleName,
                moduleid: module.id,
                create: false,
                read: false,
                update: false,
                delete: false,
              },
            };
          });

          dispatch(initFormModule(moduleMap));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const deleteModule = (formData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/module/delete-module', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          // dispatch({ type: ADD_MODULE, payload: data.data.data });
          dispatch(getModules('', 0));
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const initFormModule = (initialState) => {
  return (dispatch) => {
    dispatch({ type: INIT_FORM_MODULE, payload: initialState });
  };
};

export const updateFormModule = (moduleId, fieldName, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FORM_MODULE,
      payload: { moduleId, fieldName, value },
    });
  };
};
