import React from 'react';

const UserProfile = React.lazy(() => import('./UserProfile'));

export const profileConfig = [
  {
    path: '/user-profile',
    element: <UserProfile />,
    routeName: 'PROFILE',
  },
];
