import React, { useEffect, useState } from 'react';

import AppsContainer from '../../../@crema/core/AppsContainer';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import AppsHeader from '../../../@crema/core/AppsContainer/AppsHeader';
import AppsContent from '../../../@crema/core/AppsContainer/AppsContent';
import AppsPagination from '../../../@crema/core/AppsPagination';
import AppInfoView from '../../../@crema/core/AppInfoView';
import { Button, Input, Modal, Typography } from 'antd';
import './index.style.less';
import AppPageMetadata from '../../../@crema/core/AppPageMetadata';
import ModuleManagementTable from './ModuleManagementTable';
import { getQuestions, setPage, setSearch } from 'redux/actions/Mortageoptions';
import AddQuestions from './AddQuestions';
import { usePermissions } from 'lib/permissionHook';
import { SnackbarProvider, useSnackbar } from 'notistack';
import AwesomeDebouncePromise from "awesome-debounce-promise";
import ModalCloseConfirm from 'lib/components/ModalCloseConfirm';

const MODULE_NAME = 'MORTAGE OPTIONS';


const debounceSearchOrder = AwesomeDebouncePromise(
  (search, dispatch) => {
    dispatch(setSearch(search));
    dispatch(setPage(1));
  },
  500,
  {
    onlyResolvesLast: true,
  }
);
function MortageOptions() {
  const { messages } = useIntl();
  const dispatch = useDispatch();

  const { questions, page, search } = useSelector(
    (state) => state.mortageoptions,
  );

  const { loading } = useSelector(({ common }) => common);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { profile } = useSelector(({ profile }) => profile);
  const permission = usePermissions(MODULE_NAME, profile);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (page) => {
    // setPage(page);
    dispatch(setPage(page));

  };
  useEffect(() => {
    dispatch(getQuestions())
  }, [dispatch, search, page]);

  const onSearchOrder = (e) => {
    // setSearchQuery(e.target.value);
    debounceSearchOrder(e.target.value, dispatch);

    // setPage(0);
  };
  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
    };
  }, []);
  return (
    <>
      <AppPageMetadata title='Mortage Options' />
      <AppsContainer
        title={messages['sidebar.admin.mortageOptions']}
        fullView
        type='bottom'
      >
        <AppsHeader key={'wrap'}>
          <div className='customer-header'>
            {/* <div className='customer-header-input-view'>
              <Input
                id='user-name'
                placeholder='Search'
                type='search'
                onChange={onSearchOrder}
              />
            </div> */}
            <Typography style={{ padding: '0 0.5rem' }}>
              Total: {questions.length}
            </Typography>
            <div className='customer-header-right'>
              <Button
                // disabled={permission && !permission.create}
                type='primary' onClick={showModal}>
                Add Question
              </Button>
              {/* <AppsPagination
                className='customer-header-pagination'
                pageSize={10}
                count={questions.length}
                current={page}
                page={page}
                onChange={onChange}
              /> */}
            </div>
          </div>
        </AppsHeader>

        <AppsContent
          key={'wrap1'}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <ModuleManagementTable loading={loading} modules={questions} />
        </AppsContent>

        <AppsPagination
          key={'wrap2'}
          className='customer-footer-pagination'
          pageSize={10}
          count={questions.length}
          current={page}
          page={page}
          onChange={onChange}
        />
      </AppsContainer>

      <Modal
        title={messages['admin.addQuestion']}
        visible={isModalVisible}
        onOk={handleOk}
        footer={false}
        maskClosable={false}
        destroyOnClose={true}
        closeIcon={<ModalCloseConfirm onCancel={handleCancel} />}
      >
        <SnackbarProvider
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}>
          <AddQuestions closeModel={handleCancel} />
        </SnackbarProvider>
      </Modal>

      <AppInfoView />
    </>
  );
}

export default MortageOptions;
