import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

function ShowModules({ module }) {
  console.log("module", module)
  const newArr = module.options.sort((a, b) => a.order - b.order)
  console.log(newArr, "newArr")

  return (
    <div className='show-customer-details'>
      <p>Question : {module.question}</p>
      <Row>
        <Col>
          <p>Options : </p>
        </Col>
        <Col>

          {newArr.map((x) => <p style={{ marginLeft: 10 }}> {x.order}. {x.option}</p>)}
        </Col>
      </Row>

    </div>
  );
}

export default ShowModules;

ShowModules.propTypes = {
  module: PropTypes.object,
};
