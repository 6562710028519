import React, { useEffect, useState } from 'react';

import AppsContainer from '../../../@crema/core/AppsContainer';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import AppsHeader from '../../../@crema/core/AppsContainer/AppsHeader';
import AppsContent from '../../../@crema/core/AppsContainer/AppsContent';
import AppsPagination from '../../../@crema/core/AppsPagination';
import AppInfoView from '../../../@crema/core/AppInfoView';
import {  Input, Typography } from 'antd';
import './index.style.less';
import AppPageMetadata from '../../../@crema/core/AppPageMetadata';
import ModuleManagementTable from './ModuleManagementTable';
import { getPreQualifiedUser, setPage, setSearch } from 'redux/actions/PreQualifiedUser';
import { usePermissions } from 'lib/permissionHook';
import AwesomeDebouncePromise from "awesome-debounce-promise";

const MODULE_NAME = 'MORTAGE PRE-QUALIFIED USER';


const debounceSearchOrder = AwesomeDebouncePromise(
  (search, dispatch) => {
    dispatch(setSearch(search));
    dispatch(setPage(1));
  },
  500,
  {
    onlyResolvesLast: true,
  }
);
function PreQualifiedUser() {
  const { messages } = useIntl();
  const dispatch = useDispatch();

  const { preQulifiedUsers, page, search } = useSelector(
    (state) => state.PreQualifiedUser
  );
  
  const { loading } = useSelector(({ common }) => common);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { profile } = useSelector(({ profile }) => profile);
  const permission = usePermissions(MODULE_NAME, profile);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChange = (page) => {
    // setPage(page);
    dispatch(setPage(page));

  };
  useEffect(() => {
    dispatch(getPreQualifiedUser())
  }, [dispatch, search, page]);

  const onSearchOrder = (e) => {
    // setSearchQuery(e.target.value);
    debounceSearchOrder(e.target.value, dispatch);

    // setPage(0);
  };
  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setSearch(''));
    };
  }, []);
  return (
    <>
      <AppPageMetadata title='Mortage Options' />
      <AppsContainer
        title={messages['sidebar.admin.preQualifiedUser']}
        fullView
        type='bottom'
      >
        <AppsHeader key={'wrap'}>
          <div className='customer-header'>
            {/* <div className='customer-header-input-view'>
              <Input
                id='user-name'
                placeholder='Search'
                type='search'
                onChange={onSearchOrder}
              />
            </div> */}
            <Typography style={{ padding: '0 0.5rem' }}>
              Total: {preQulifiedUsers.length}
            </Typography>
         
          </div>
        </AppsHeader>

        <AppsContent
          key={'wrap1'}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <ModuleManagementTable loading={loading} modules={preQulifiedUsers}/>
        </AppsContent>

        {/* <AppsPagination
          key={'wrap2'}
          className='customer-footer-pagination'
          pageSize={10}
          count={preQulifiedUsers.length}
          current={page}
          page={page}
          onChange={onChange}
        /> */}
      </AppsContainer>

     

      <AppInfoView />
    </>
  );
}

export default PreQualifiedUser;
