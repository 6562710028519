import {Navigate, useRoutes} from 'react-router-dom';
import {authRole, RoutePermittedRole} from '../../shared/constants/AppEnums';
import {useSelector} from 'react-redux';

/**
 * @param {Object} structure - The passed object that defines the routes.
 * @param {boolean} structure.isAuthenticated - [Required] in order to differentiate between LoggedIn/Loggedout users
 * @param {string} structure.userRole - [Optional] in order to differentiate between admin and normal users
 * @param {object} [structure.anonymousStructure] - it's an object that has only [ routes ] array, [ these routes available for All personas ]
 * @param {object} [structure.authorizedStructure] - it's an object that has [ fallbackPath: {string}, routes: {array} ], fallbackPath: is used for redirect when a logged [in] user tried to access unAuthorized route, routes: only The Logged [in] Routes Available
 * @param {object} [structure.unAuthorizedStructure] - it's an object that has [ fallbackPath: {string}, routes: {array} ], fallbackPath: is used for redirect when a logged [out] user tried to access route that requires [Authorization] , routes: only The Logged [out] Routes Available
 * @param {component} [structure.component fallbackComponent] - in order to redirect in all cases if the route doesn't match.
 * @param {unAuthorizedComponent} [structure.unAuthorizedComponent] - in order to show not permitted route.
 * @returns {Array}
 */

const generateRoutes = (structure) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const { modules } = useSelector(({ roleManagement }) => roleManagement);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {profile} = useSelector(({profile}) => profile);

  const {
    isAuthenticated = false,
    anonymousStructure = {},
    authorizedStructure = {},
    unAuthorizedStructure = {},
    userRole = authRole.user,
  } = structure || {};

  const dynamicRoutes = [];

  if (anonymousStructure) {
    dynamicRoutes.push(
      ...routesGenerator(isAuthenticated, anonymousStructure, 'anonymous'),
    );
  }

  if (authorizedStructure) {
    dynamicRoutes.push(
      ...routesGenerator(
        isAuthenticated,
        authorizedStructure,
        'authorized',
        isAuthenticated ? userRole : null,

        profile,
      ),
    );
  }

  if (unAuthorizedStructure) {
    dynamicRoutes.push(
      ...routesGenerator(
        isAuthenticated,
        unAuthorizedStructure,
        'unAuthorized',
      ),
    );
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useRoutes(dynamicRoutes);
};

/**
 * path: string
 * component: React.Component
 * routeProps: Object -----> To override route props
 * userRole: string -----> To override route props
 * redirectPath: String ----> To redirect to specific location
 * showRouteIf: to override when to show the component or when to [ Navigate ]
 */
const routesGenerator = (
  isAuthenticated = false,
  routeSet = {},
  type = 'anonymous',
  userRole,

  profile = {},
) => {
  const generatedRoutes = [];
  const {fallbackPath = ''} = routeSet || {};

  const isAnonymous = type === 'anonymous';
  const isAuthorized = type === 'authorized';

  const findModuleData = (index) => {
    if (index > -1) {
      return profile?.accessModules[index];
    } else {
      return false;
    }
  };

  const findModuleIndex = (moduleName) => {
    return profile?.accessModules?.findIndex((module) => {
      return module?.moduleName?.toString().toUpperCase() === moduleName;
    });
  };

  if (routeSet?.routes) {
    const routes = routeSet.routes;
    if (Array.isArray(routes) && routes.length > 0) {
      routes.forEach((route /*index*/) => {
        const {
          path = '',
          permittedRole = RoutePermittedRole.user,
          // routeProps = {},
          redirectPath = '',
          showRouteIf = true,
        } = route || {};
        // Show Route only [ in The list ] if this prop is true
        if (showRouteIf) {
          // check the mandatory props for a routes
          if (!path) {
            console.log(
              `A [route] is skipped because one of the following, No valid [path] prop provided for the route`,
              isAuthenticated,
            );
          } else {
            if (isAnonymous) {
              return generatedRoutes.push(route);
            }
            if (isAuthorized) {
              const renderCondition = isAuthorized
                ? isAuthenticated
                : !isAuthenticated;

              if (Array.isArray(route.path)) {
                // eslint-disable-next-line array-callback-return
                route.path.map((path) => {
                  generatedRoutes.push(
                    renderCondition
                      ? userRole.indexOf(permittedRole) > -1
                        ? {
                            element: route.element,
                            path: path,
                            permittedRole: route.permittedRole,
                          }
                        : {
                            path: path,
                            element: routeSet.unAuthorizedComponent,
                          }
                      : {
                          path: path,
                          element: (
                            <Navigate
                              to={redirectPath || fallbackPath}
                              replace
                            />
                          ),
                        },
                  );
                });
              } else {
                // Permission Management Condition here:

                if (!profile?.email) {
                  generatedRoutes.push(
                    renderCondition
                      ? userRole.indexOf(permittedRole) > -1
                        ? route
                        : {
                            path: route.path,
                            element: routeSet.unAuthorizedComponent,
                          }
                      : {
                          path: route.path,
                          element: (
                            <Navigate
                              to={redirectPath || fallbackPath}
                              replace
                            />
                          ),
                        },
                  );
                } else {
                  if (profile?.role === "SuperAdmin") {
                    generatedRoutes.push(
                      renderCondition
                        ? userRole.indexOf(permittedRole) > -1
                          ? route
                          : {
                              path: route.path,
                              element: routeSet.unAuthorizedComponent,
                            }
                        : {
                            path: route.path,
                            element: (
                              <Navigate
                                to={redirectPath || fallbackPath}
                                replace
                              />
                            ),
                          },
                    );
                  } else {
                    if (
                      route.routeName === 'DASHBOARD' ||
                      route.routeName === 'PROFILE'
                    ) {
                      generatedRoutes.push(
                        renderCondition
                          ? route
                            : {
                              path: route.path,
                              element: (
                                <Navigate
                                  to={redirectPath || fallbackPath}
                                  replace
                                />
                              ),
                            },
                      );
                      // generatedRoutes.push(
                      //   renderCondition
                      //     ? userRole.indexOf(permittedRole) > -1
                      //       ? route
                      //       : {
                      //           path: route.path,
                      //           element: routeSet.unAuthorizedComponent,
                      //         }
                      //     : {
                      //         path: route.path,
                      //         element: (
                      //           <Navigate
                      //             to={redirectPath || fallbackPath}
                      //             replace
                      //           />
                      //         ),
                      //       },
                      // );
                    } else {
                      const permissionModule = findModuleData(
                        findModuleIndex(route.routeName),
                      );
                      if (permissionModule) {
                        if (permissionModule.read) {
                          generatedRoutes.push(
                            renderCondition
                              ? route
                                : {
                                  path: route.path,
                                  element: (
                                    <Navigate
                                      to={redirectPath || fallbackPath}
                                      replace
                                    />
                                  ),
                                },
                          );
                          // generatedRoutes.push(
                          //   renderCondition
                          //     ? userRole.indexOf(permittedRole) > -1
                          //       ? route
                          //       : {
                          //           path: route.path,
                          //           element: routeSet.unAuthorizedComponent,
                          //         }
                          //     : {
                          //         path: route.path,
                          //         element: (
                          //           <Navigate
                          //             to={redirectPath || fallbackPath}
                          //             replace
                          //           />
                          //         ),
                          //       },
                          // );
                        } else {
                        }
                      } else {
                      }
                    }
                  }
                }
              }

              return generatedRoutes;
            }
            const renderCondition = isAuthorized
              ? isAuthenticated
              : !isAuthenticated;

            if (Array.isArray(route.path)) {
              // eslint-disable-next-line array-callback-return
              route.path.map((path) => {
                generatedRoutes.push(
                  renderCondition
                    ? {
                        element: route.element,
                        path: path,
                        permittedRole: route.permittedRole,
                      }
                    : {
                        path: path,
                        element: (
                          <Navigate to={redirectPath || fallbackPath} replace />
                        ),
                      },
                );
              });
            } else {
              generatedRoutes.push(
                renderCondition
                  ? route
                  : {
                      path: route.path,
                      element: (
                        <Navigate to={redirectPath || fallbackPath} replace />
                      ),
                    },
              );
            }

            return generatedRoutes;
          }
        }
      });
    }
  } else {
    console.log(`[routes] prop can't be found in ${type}Structure Object`);
  }
  return generatedRoutes;
};

export default generateRoutes;
