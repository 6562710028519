import React from 'react';
import {AppLoader} from '../index';
import PropTypes from 'prop-types';
import {useAuthUser} from './AuthHooks';

const AuthRoutes = ({children}) => {
  const {isLoading} = useAuthUser();
  //* Loading Error Fixed
  // return isLoading ? <AppLoader /> : <>{children}</>;
  return isLoading ? <></> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
