import {
    SET_PAGE,
    SET_SEARCH,
    GET_EXCLUSIVE_PROPERTY
} from '../../shared/constants/ActionTypes';

const initialexclusiveproperty = {
    propertyData: [],
    propertyCount: 0,
    page: 1,
    search: ''
};

const exclusivepropertyReducer = (state = initialexclusiveproperty, action) => {
    switch (action.type) {
        case GET_EXCLUSIVE_PROPERTY:
            return {
                ...state,
                propertyData: action.payload.data,
                propertyCount: action.payload.total,
            };

        case SET_PAGE:
            return {
                ...state,
                page: action.payload,
            };

        case SET_SEARCH:
            return {
                ...state,
                search: action.payload,
            };

        default:
            return state;
    }
};

export default exclusivepropertyReducer;
