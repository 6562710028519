import {Button, Modal} from 'antd';
import {AiOutlineClose} from 'react-icons/ai';
import PropTypes from 'prop-types';
import React from 'react';
import {ExclamationCircleOutlined} from '@ant-design/icons';

export default function ModalCloseConfirm({onCancel}) {
  const showModal = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to close the dialog?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: onCancel,
    });
  };

  return (
    <Button onClick={showModal}>
      <AiOutlineClose />
    </Button>
  );
}

ModalCloseConfirm.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
