import {
  GET_QUESTIONS,
  SET_PAGE,
  SET_SEARCH
} from '../../shared/constants/ActionTypes';

const initialRoleManagement = {

  questions: [],
  questionCount: 0,
  page: 1,
  search: '',
};

// TODO: HAVE TO ADD PAGE AND SEARCH IN STATE

const mortageOptionsReducer = (state = initialRoleManagement, action) => {
  switch (action.type) {

    case GET_QUESTIONS:
      return {
        ...state,
        questions: action.payload.data,
        questionCount: action.payload.count,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

export default mortageOptionsReducer;
