import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { updateModule } from 'redux/actions';

function EditQuestions({ data, closeModel }) {
  const dispatch = useDispatch();

  const onFinish = (values) => {
    console.log('Success:', values);
    dispatch(updateModule({ ...values, moduleId: data._id }));
    closeModel();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      className='edit-customer-form'
      name='basic'
      initialValues={{ moduleName: data.moduleName }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name='moduleName'
        rules={[{ required: true, message: 'Please input Module Name!' }]}
      >
        <Input placeholder='Module Name' />
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}

export default EditQuestions;

EditQuestions.propTypes = {
  data: PropTypes.object,
  closeModel: PropTypes.func,
};
