import React, { useState } from 'react';
import { Form, Button, Input, Row, Col, Tag, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { addQuestions, getQuestions } from 'redux/actions';
import PropTypes from 'prop-types';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useSnackbar } from 'notistack';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

function AddQuestions({ closeModel }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar()
  const [options, setOptions] = useState([])
  const [singleQuestion, setSingleQuestion] = useState("")
  const {Option}= Select
  const questionTypes =["Radio","DropDown","Text"]
  const handleAuthor = (e) => {
    setSingleQuestion(e.target.value)
  }
  const removeAuthor = (value) => {
    const newAuthors = options?.filter((item) => {
      return item !== value;
    });

    setOptions(newAuthors);
  };
  const addQuestion = () => {
    if (singleQuestion.trim().length !== 0) {
      const authorName = options.find(
        (author) => author === singleQuestion
      );

      if (authorName) {
        try {
          enqueueSnackbar("option already exist.", { variant: "error" })
        } catch (error) {
          console.error(error);
        }
      } else {
        setOptions((prev) => [...prev, singleQuestion.trim()]);
        setSingleQuestion("");
      }
    }
  };

  const onFinish = (values) => {
    const newObj = { ...values, options: options }
    console.log("newObj", newObj)
    dispatch(addQuestions(newObj));
    dispatch(getQuestions())
    closeModel();
  };

  return (
    <Form
      className='edit-customer-form'
      name='validate_other'
      {...formItemLayout}
      onFinish={onFinish}
      initialValues={{
        question: '',
        questionType: '',
        options: options || [],
      }}>
      <Form.Item
        label='Question'
        name='question'
        rules={[{ required: true, message: 'Please add question' }]}>
        <Input name='question' placeholder='Question' />
      </Form.Item>
      <br />
      <Form.Item
        label='Question Type'
        name='questionType' 
        rules={[{ required: true, message: 'Please add question type' }]}>
        <Select
          style={{ minWidth: '130px', maxWidth: '200px' }}
          name="questionType"
          placeholder="Question Type"
        >
          {questionTypes.map((qt,i) => {
            return <Option key={i} value={qt}>{qt}</Option>;
          })}
        </Select>
      </Form.Item>{' '}
      <br />
      <Form.Item
        // name="options"
        label="* Options"
        rules={[
          {
            required: true,
            whitespace: true,
            // message: "Please add Options",
          },
        ]}
      >
        <Row gutter={24}>
          <Col span={20}>
            <Input
              name='options'
              placeholder="Options"
              value={singleQuestion}
              onChange={handleAuthor}
            />
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={addQuestion}
            />
          </Col>
        </Row>
        <br />
        {options &&
          options?.map((item) => (
            <Tag
              key={item}
              closable
              style={{ marginBottom: "5px" }}
              onClose={() => removeAuthor(item)}
            >
              {item}
            </Tag>
          ))}
      </Form.Item>
      <Form.Item
        wrapperCol={{
          span: 12,
          offset: 6,
        }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AddQuestions;

AddQuestions.propTypes = {
  closeModel: PropTypes.func,
};
