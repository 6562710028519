import {
  GET_USERS,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_PAGE,
  SET_SEARCH,
  SET_ROLES,
  SET_SUBROLES,
  SHOW_MESSAGE,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
import { addProfile } from './Profile';

export const getUsers = (limit = 10) => {
  return (dispatch, getState) => {
    const state = getState().users;

    dispatch({ type: FETCH_START });
    jwtAxios
      .get('/users', {
        params: {
          pageNo: state.page,
          pageSize: limit,
          keyword: state.search
        }
        // search: state.search,
        // page: state.page,
        // limit,
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_USERS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.msg
            ? error?.response?.data?.msg
            : error?.message,
        });
      });
  };
};

export const addUser = (formData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/createuser', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SHOW_MESSAGE, payload: data?.data?.message });
          // dispatch(push('/admin/users'));
          dispatch(getUsers());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message ?? error?.message,
        });
      });
  };
};

export const editUser = (formData, id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    let data = { ...formData, id };
    jwtAxios
      .patch(`/updateuser`, data)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch(getUsers());
          dispatch({
            type: SHOW_MESSAGE,
            payload: data?.data?.message,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.msg
            ? error?.response?.data?.msg
            : error?.message,
        });
      });
  };
};

export const editProfile = (formData, id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    let data = { ...formData, id };
    jwtAxios
      .patch(`/updateuser`, data)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SHOW_MESSAGE,
            payload: data?.data?.message,
          });
          let userData = JSON.parse(localStorage.getItem('user_data'));
          let mergeObj = { ...formData, id: userData.id };
          localStorage.setItem('user_data', JSON.stringify(mergeObj));
          dispatch(addProfile(mergeObj))
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.msg
            ? error?.response?.data?.msg
            : error?.message,
        });
      });
  };
};

export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .delete(`/deleteuser`, {
        params: {
          userId: id
        }
      })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch(getUsers());
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.msg
            ? error?.response?.data?.msg
            : error?.message,
        });
      });
  };
};

export const forgotPassword = (formData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/forgotpassword', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SHOW_MESSAGE,
            payload: data?.data?.message,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.msg
            ? error?.response?.data?.msg
            : error?.message,
        });
      });
  };
};

export const resetPassword = (formData) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .post('/resetPassword', formData)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.msg
            ? error?.response?.data?.msg
            : error?.message,
        });
      });
  };
};

export const setPage = (page) => {
  return (dispatch) => {
    dispatch({ type: SET_PAGE, payload: page });
  };
};

export const setSearch = (search) => {
  return (dispatch) => {
    dispatch({ type: SET_SEARCH, payload: search });
  };
};

export const getRoles = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('/getroles')
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SET_ROLES,
            payload: data.data.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message ?? error?.message,
        });
      });
  };
};

export const getSubroles = (roleId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get(`/subrole/listByRoleId/${roleId}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SET_SUBROLES,
            payload: data.data.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message ?? error?.message,
        });
      });
  };
};
