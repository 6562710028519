import {
  GET_QUESTIONS,
  SET_PAGE,
  SET_SEARCH,
  GET_PRE_QULI_USERS,

} from '../../shared/constants/ActionTypes';

const initialRoleManagement = {

  preQulifiedUsers: [],
  usersCount: 0,
  page: 1,
  search: '',
};

// TODO: HAVE TO ADD PAGE AND SEARCH IN STATE

const PreQualifiedUser = (state = initialRoleManagement, action) => {
  switch (action.type) {

    case GET_PRE_QULI_USERS:
      return {
        ...state,
        preQulifiedUsers: action.payload.data.map((x)=>{
          return {
            ...x,
            fullName:`${x.firstName} ${x.lastName}`
          }
        }),
        usersCount: action.payload.count,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

export default PreQualifiedUser;
