import {
    GET_LENDERS,
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_ERROR,
    SET_PAGE,
    SET_SEARCH,
    SHOW_MESSAGE,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
export const getLenders = (limit = 10) => {
    return (dispatch, getState) => {
        const state = getState().lenders;
        dispatch({ type: FETCH_START });
        jwtAxios
            .get('/lenders', {
                params: {
                    pageNo: state.page,
                    pageSize: limit,
                    keyword: state.search
                }
                // search: state.search,
                // page: state.page,
                // limit,
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({
                        type: GET_LENDERS,
                        payload: data.data,
                    });
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: 'Something went wrong, Please try again!',
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_ERROR,
                    payload: error?.response?.data?.msg
                        ? error?.response?.data?.msg
                        : error?.message,
                });
            });
    };
};

export const addLender = (formData) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        jwtAxios
            .post('/savelender', formData, {
                params: formData
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: SHOW_MESSAGE, payload: data?.data?.message });
                    // dispatch(push('/admin/users'));
                    dispatch(getLenders());
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: 'Something went wrong, Please try again!',
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_ERROR,
                    payload: error?.response?.data?.message ?? error?.message,
                });
            });
    };
};

export const editLender = (formData, id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        let data = { ...formData, Id: id };
        jwtAxios
            .post(`/savelender`, data, {
                params: data
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch(getLenders());
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: data?.data?.message,
                    });
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: 'Something went wrong, Please try again!',
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_ERROR,
                    payload: error?.response?.data?.msg
                        ? error?.response?.data?.msg
                        : error?.message,
                });
            });
    };
};

export const deleteLender = (id) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        jwtAxios
            .patch(`/deletelender`, {}, {
                params: {
                    Id: id
                }
            })
            .then((data) => {
                if (data.status === 200) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch(getLenders());
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: data?.data?.message,
                    });
                } else {
                    dispatch({
                        type: FETCH_ERROR,
                        payload: 'Something went wrong, Please try again!',
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_ERROR,
                    payload: error?.response?.data?.msg
                        ? error?.response?.data?.msg
                        : error?.message,
                });
            });
    };
};

export const setPage = (page) => {
    return (dispatch) => {
        dispatch({ type: SET_PAGE, payload: page });
    };
};

export const setSearch = (search) => {
    return (dispatch) => {
        dispatch({ type: SET_SEARCH, payload: search });
    };
};
