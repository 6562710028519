import {
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_ERROR,
    GET_CATEGORIES,
    SHOW_MESSAGE,
  } from '../../shared/constants/ActionTypes';
  
  import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
export const addCategory = (formData) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      jwtAxios
        .post('/addpropertycategory', {categoryName: formData.categoryName})
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            if(data.data.succeeded) {
                dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
                // dispatch({type: ADD_CATEGORY, payload: data.data.data});
                dispatch(getCategory('', 0));
            } else {
                dispatch({type: FETCH_ERROR, payload: data?.data?.message});
            }
            
          } else {
            console.log(data)
            dispatch({
              type: FETCH_ERROR,
              payload: 'Something went wrong, Please try again!',
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
        });
    };
  };
  
  export const updateCategory  = (formData) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      jwtAxios
        .put('/updatepropertycategory', formData)
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            // dispatch({ type: ADD_MODULE, payload: data.data.data });
            if(data.data.succeeded) {
                dispatch({type: SHOW_MESSAGE, payload: data?.data?.message});
                // dispatch({type: ADD_CATEGORY, payload: data.data.data});
                dispatch(getCategory('', 0));
            } else {
                dispatch({type: FETCH_ERROR, payload: data?.data?.message});
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: 'Something went wrong, Please try again!',
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
        });
    };
  };
  
  export const getCategory = () => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      jwtAxios
        .get('/getpropertycategories')
        .then((data) => {
          if (data.status === 200) {
            console.log(data.data.data)
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_CATEGORIES,
              payload: {
                data: data.data.data,
                total: data.total
              },
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: 'Something went wrong, Please try again!',
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
        });
    };
  };
  
  export const deleteCategory  = (formData) => {
    return (dispatch) => {
      dispatch({type: FETCH_START});
      jwtAxios
        .delete('/deletepropertycategory', {
            params: {
                id: formData.id
            }
        })
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            // dispatch({ type: ADD_MODULE, payload: data.data.data });
            dispatch(getCategory('', 0));
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: 'Something went wrong, Please try again!',
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
        });
    };
  };