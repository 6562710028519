import {appMode} from '../shared/constants/AppConst';

export const server =
  appMode === 'development'
    ? 'https://cribzzz-api.apps.openxcell.dev'
    : 'https://api.cribzzzz.com';

// export const server =
//   appMode === "development" ? "https://ooa-backend.api.openxcell.dev"
//     : "https://ooa-backend.api.openxcell.dev";
