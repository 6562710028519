import {
  UPDATE_PROFILE,
  GET_PROFILE,
  CHANGE_PASSWORD,
  ADD_PROFILE,
} from '../../shared/constants/ActionTypes';

const initialProfile = {
  profile: {},
};

const profileReducer = (state = initialProfile, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        profile: action.payload,
      };
    case UPDATE_PROFILE:
      return {
        profile: action.payload,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
      };
    case ADD_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
