import {Link, useLocation} from 'react-router-dom';
import {Menu} from 'antd';
import React from 'react';
import routesConfig from '../../pages/routeConfig';
import {useIntl} from 'react-intl';
import {useSidebarContext} from './AppContextProvider/SidebarContextProvider';
import {useSelector} from 'react-redux';

function getStyles(item, sidebarColorSet, isSidebarBgImage, index, isGroup) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {pathname} = useLocation();
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/');

  if (index === 0 || isGroup) {
    return {
      color: sidebarColorSet.sidebarTextColor,
      backgroundColor: isSidebarBgImage ? '' : sidebarColorSet.sidebarBgColor,
    };
  } else {
    const isActive = defaultOpenKeys[index] === item.id;
    return {
      color: isActive
        ? sidebarColorSet.sidebarMenuSelectedTextColor
        : sidebarColorSet.sidebarTextColor,
      backgroundColor: isActive
        ? sidebarColorSet.sidebarMenuSelectedBgColor
        : isSidebarBgImage
        ? ''
        : sidebarColorSet.sidebarBgColor,
    };
  }
}

const renderMenuItemChildren = (item) => {
  const {icon, messageId, path} = item;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {messages} = useIntl();

  if (path && path.includes('/'))
    return (
      <Link to={path}>
        {icon &&
          (React.isValidElement(icon) ? (
            <span className='ant-menu-item-icon'>{icon}</span>
          ) : (
            <icon className='ant-menu-item-icon' />
          ))}
        <span data-testid={messageId.toLowerCase + '-nav'}>
          {messages[messageId]}
        </span>
      </Link>
    );
  else {
    return (
      <>
        {icon &&
          (React.isValidElement(icon) ? (
            <span className='ant-menu-item-icon'>{icon}</span>
          ) : (
            <icon className='ant-menu-item-icon' />
          ))}
        <span data-testid={messageId.toLowerCase + '-nav'}>
          {messages[messageId]}
        </span>
      </>
    );
  }
};

const renderMenuItem = (item, sidebarColorSet, isSidebarBgImage, index) => {
  return item.type === 'collapse' ? (
    <Menu.SubMenu
      style={getStyles(item, sidebarColorSet, isSidebarBgImage, index, true)}
      key={item.path ? item.path : item.id}
      title={renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}
    >
      {item.children.map((item) =>
        renderMenuItem(item, sidebarColorSet, isSidebarBgImage, index + 1),
      )}
    </Menu.SubMenu>
  ) : (
    <Menu.Item
      key={item.id}
      style={getStyles(item, sidebarColorSet, isSidebarBgImage, index)}
    >
      {item.children
        ? item.children
        : renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}
    </Menu.Item>
  );
};

const renderMenu = (item, sidebarColorSet, isSidebarBgImage, index) => {
  return item.type === 'group' ? (
    <Menu.ItemGroup
      style={getStyles(item, sidebarColorSet, isSidebarBgImage, index, true)}
      key={item.path ? item.path : item.id}
      title={renderMenuItemChildren(item, sidebarColorSet, isSidebarBgImage)}
    >
      {item.children.map((item) =>
        renderMenuItem(item, sidebarColorSet, isSidebarBgImage, index + 1),
      )}
    </Menu.ItemGroup>
  ) : (
    <Menu.Item
      key={item.id}
      exact={item.exact}
      style={getStyles(item, sidebarColorSet, isSidebarBgImage, index)}
    >
      {item.children
        ? item.children
        : renderMenuItemChildren(
            item,
            sidebarColorSet,
            isSidebarBgImage,
            index,
          )}
    </Menu.Item>
  );
};

export const getRouteMenus = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {sidebarColorSet} = useSidebarContext();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {isSidebarBgImage} = useSidebarContext();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {profile} = useSelector(({profile}) => profile);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const { modules } = useSelector(({ roleManagement }) => roleManagement);

  const findModuleData = (index) => {
    if (index > -1) {
      return profile?.accessModules[index];
    } else {
      return false;
    }
  };

  const findModuleIndex = (moduleName) => {
    return profile?.accessModules?.findIndex((module) => {
      return module?.moduleName?.toString().toUpperCase()  === moduleName;
    });
  };

  // Role & Permission Management Condition Here

  if (!profile?.email) {
    return routesConfig.map((route) => {
      return renderMenu(route, sidebarColorSet, isSidebarBgImage, 0);
    });
  }
  // if (profile?.subroleId?.name === 'Super Admin') {
  if (profile?.role === "SuperAdmin") {
    return routesConfig.map((route) => {
      return renderMenu(route, sidebarColorSet, isSidebarBgImage, 0);
    });
  } else {
    return routesConfig.map((route1) => {
      let childern1 = route1.children.map((route) => {
        if (!route) {
          return null;
        }

        if (!route.routeName) {
          return null;
        }
        if (route.routeName === 'DASHBOARD') {
          return route;
        }
        const permissionModule = findModuleData(
          findModuleIndex(route.routeName),
        );

        if (permissionModule) {
          if (permissionModule.read) {
            return route;
          } else {
            return null;
          }
        } else {
          return route;
        }
      });
      route1.children = childern1.filter((data) => data !== null);

      return renderMenu(route1, sidebarColorSet, isSidebarBgImage, 0);
    });
  }

  // return routesConfig.map((route) =>
  //   renderMenu(route, sidebarColorSet, isSidebarBgImage, 0)
  // );
};
